import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ConfirmationModalModel,
  DropdownModel,
  DropdownOptionModel,
  FileInputModel,
  FridayScratchApiType,
  FridayScratchListElementModel,
  FridayScratchListElementModelType,
  ModalModel,
  PageModel,
  PageRoutes,
  RootType,
} from '../../../internal';

export const CreateFridayScratchModel = types
  .compose(
    PageModel,
    types.model({
      record: types.maybe(FridayScratchListElementModel),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // Clear any temporary data or UI components
        // self.clearData();
      },
      async beforePageEnter() {
        const id = self.root.router.queryParams.id;

        if (!id) return;
        const record = await self.root.api.fridayScratchBanners.get(id);
        if (!record) return;
        this.setRecord(record);
      },
      setRecord(record: FridayScratchListElementModelType) {
        self.record = record;
      },
      getSegmentDropdown() {
        return (
          self.components.get('segmentation') ||
          self.addComponent(
            DropdownModel.create({
              id: 'segmentation',
              label: 'basic:segment',
              placeholder: 'basic:pick',
              initialValue: self.record?.segmentation || '',
              value: self.record?.segmentation || '',
              disabled: !!self.record?.segmentation,
              isRequired: true,
              options: {
                0: DropdownOptionModel.create({
                  id: '0',
                  value: 'basic:consumer',
                }),
                1: DropdownOptionModel.create({
                  id: '1',
                  value: 'basic:business',
                }),
              },
            })
          )
        );
      },
      getTypeDropdown() {
        return (
          self.components.get('scratch_banner_type') ||
          self.addComponent(
            DropdownModel.create({
              id: 'scratch_banner_type',
              label: 'basic:type_dropdown',
              placeholder: 'basic:pick',
              isRequired: true,
              initialValue: self.record?.scratchBannerType || '',
              value: self.record?.scratchBannerType || '',
              disabled: !!self.record?.scratchBannerType,
              options: {
                0: DropdownOptionModel.create({
                  id: '0',
                  value: 'basic:scratch',
                }),
                1: DropdownOptionModel.create({
                  id: '1',
                  value: 'basic:counter',
                }),
              },
            })
          )
        );
      },
      getImageInput() {
        return (
          self.components.get('image_friday') ||
          self.addComponent(
            FileInputModel.create({
              id: 'image_friday',
              maxNumberOfFiles: 1,
              src: self.record?.image || '',
            })
          )
        );
      },
      getConfirmationModal() {
        return (
          self.components.get('confirmation_modal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'confirmation_modal',
              labelNo: 'basic:no',
              labelYes: 'basic:yes',
              afterYes: this.deleteBanner,
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      openConfirmationModal() {
        this.getConfirmationModal().open();
      },
      async deleteBanner() {
        const id = self.root.router.queryParams.id;
        const type = self.root.router.queryParams.type;
        await self.root.api.fridayScratchBanners.delete(id);
        this.navigateToFridayScratchPage(type);
      },
      navigateToFridayScratchPage(type: string) {
        self.root.router.navigate({
          newView: PageRoutes.Banners.id,
          params: {
            type,
          },
        });
      },
      async submitData() {
        const imageComponent = this.getImageInput();
        const segmentDropdown = this.getSegmentDropdown();
        const typeDropdown = this.getTypeDropdown();

        let image = imageComponent.src || '';
        const rawImg = imageComponent.file;

        if (rawImg) {
          const response = await self.root.api.fridayScratchBanners.uploadImage(
            rawImg,
            'image'
          );
          const fileName = response?.data?.filename;
          if (fileName) {
            image = `${process.env.REACT_APP_IMAGE_BASE_URL}scratch-uploads/${fileName}`;
          }
        }

        const data: FridayScratchApiType = {
          segmentation: segmentDropdown.value,
          image,
          scratchBannerType: typeDropdown.value,
          isActive: 1,
        };

        const id = self.root.router.queryParams.id;
        if (id) {
          await self.root.api.fridayScratchBanners.update(id, data);
        } else {
          await self.root.api.fridayScratchBanners.create(data);
        }
        const type = self.root.router.queryParams.type;
        this.navigateToFridayScratchPage(type);
      },
    };
  })
  .named('CreateFridayScratchModel');

export type CreateFridayScratchModelType = Instance<
  typeof CreateFridayScratchModel
>;
