import * as React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './FridayScratchElement.module.scss';
import { FridayScratchListElementModelType } from '../../../models/domain/friday-scratch/FridayScratchListElementModel';
import EDIT_ICON from '../../../assets/icons/icons8_edit_property_96px.png';
import DELETE_ICON from '../../../assets/icons/icons8_trash_32px.png';
import { ConfirmationModal } from '../../../components/UI/confirmation-modal/ConfirmationModal';
export const FridayScratchElement = observer(
  (props: {
    model: FridayScratchListElementModelType;
    openScratchDetailsPage: (id: number) => void;
    openModal: () => void;
    currentPage: any;
    index: number;
    activePaginationPage: number;
    lastPaginationPage: number;
    naturalIndex: number;
    currentPageElementCount: number;
  }): JSX.Element => {
    const {
      model,
      currentPage,
      activePaginationPage,
      lastPaginationPage,
      naturalIndex,
      currentPageElementCount,
    } = props;
    const { t } = useTranslation();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      currentPage.toggleActive(model.id, e.target.checked);
    };

    const isFirstElement = activePaginationPage === 1 && naturalIndex === 0;
    const isLastElement =
      activePaginationPage === lastPaginationPage &&
      naturalIndex + 1 === currentPageElementCount;

    const getFridayBannerType = (type: string) => {
      return type === '0' ? t('banners:scratch') : t('banners:counter');
    };

    const getFridayBannerSegment = (segment: string) => {
      return segment === '0' ? t('basic:consumer') : t('basic:business');
    };

    return (
      <div className={styles.Container}>
        <div className={styles.LeftContainer}>
          <img
            className={styles.Image}
            src={model.image || ''}
            alt={t('basic:y.friday')}
          />

          <div className={styles.InfoContainer}>
            <div className={styles.TypeContainer}>
              <span>{t('basic:type_dropdown')}: </span>
              <span>{getFridayBannerType(model.scratchBannerType)}</span>
            </div>
            <div>
              <span>{t('basic:segmentation')}: </span>
              <span>{getFridayBannerSegment(model.segmentation)}</span>
            </div>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <img
            className={styles.Edit}
            onClick={() => {
              props.openScratchDetailsPage(model.id);
            }}
            src={EDIT_ICON}
            alt={'edit'}
          />
          <img
            className={styles.Delete}
            onClick={() => props.openModal()}
            src={DELETE_ICON}
            alt={'delete'}
          />
        </div>
      </div>
    );
  }
);
