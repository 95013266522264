import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useMst } from '../../../internal';
import { FileInput } from '../../../components/UI/file-input/FileInput';
import { Button } from '../../../components/UI/buttons/regular/Button';
import cancel from '../../../assets/icons/cancel.png';
import PLUS from '../../../layouts/images/icon-add.svg';
import arrowBack from '../../../assets/icons/arrow-back.svg';
import styles from '../banners/Banners.module.scss';
import { Dropdown } from '../../../components/UI/dropdown/Dropdown';
import { ConfirmationModal } from '../../../components/UI/confirmation-modal/ConfirmationModal';

export const CreateFridayScratchPage = observer((): ReactElement => {
  const { t } = useTranslation();
  const mst = useMst();
  const {
    currentPage,
    router: { queryParams },
  } = mst;
  const { type, id } = queryParams;

  const segmentDropdown = currentPage.getSegmentDropdown();
  const typeDropdown = currentPage.getTypeDropdown();
  const imageInput = currentPage.getImageInput();

  const removeImage = (e: React.MouseEvent<HTMLElement>, fieldType: string) => {
    e.preventDefault();
    imageInput.clearFiles();
  };

  return (
    <div className={styles.PageContainer}>
      <div
        className={styles.NavigateBack}
        onClick={() => {
          currentPage.navigateToFridayScratchPage(type);
        }}
      >
        <img className={styles.Arrow} src={arrowBack} alt={t('basic:back')} />
        <span>{t('banners:back_to_banners')}</span>
      </div>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>
          {t(id ? 'basic:edit_banner' : 'basic:create_friday')}
        </div>
      </div>
      <div className={styles.AdListOuterContainer}>
        <div className={styles.InputFieldContainer}>
          <Dropdown model={segmentDropdown} />
        </div>
        <div className={styles.InputFieldContainer}>
          <Dropdown model={typeDropdown} />
        </div>
        <div>
          <label className={styles.InputLabel}>
            {t('banners:upload_image')}
          </label>
          <div className={styles.FileInput}>
            <FileInput
              model={imageInput}
              fridayScratchbanners
              ImageWrapper={({ children }: { children: ReactNode }) => (
                <div className={styles.UploadContainer}>
                  {children}
                  <img
                    src={cancel}
                    alt="cancel"
                    className={styles.DeleteButton}
                    onClick={(e) => removeImage(e, 'image_friday')}
                  />
                </div>
              )}
              LabelRenderer={() => (
                <div className={styles.UploadContainer}>
                  <img className={styles.PlusIcon} src={PLUS} alt="icon" />
                  <div className={styles.UploadFileDescription}>
                    {t('banners:add_image')}
                  </div>
                  <div className={styles.Description}>
                    {t('banners:y.friday_optimized')}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <Button onClick={currentPage.submitData}>{t('banners:save')}</Button>

        {id && (
          <p
            onClick={currentPage.openConfirmationModal}
            className={styles.DeleteBanner}
          >
            {t('banners:delete_banner')}
          </p>
        )}
      </div>
      <ConfirmationModal model={currentPage.getConfirmationModal()}>
        <>
          <div>
            <strong>{t('banners:delete_title')}</strong>
          </div>
          <div>{t('banners:delete_confirmation')}</div>
        </>
      </ConfirmationModal>
    </div>
  );
});
