import { observer } from 'mobx-react';

import styles from './FridayScratchList.module.scss';
import { FridayScratchListElementModelType } from '../../../models/domain/friday-scratch/FridayScratchListElementModel';
import { Show } from '../../../components/UI/show/Show';
import { FridayScratchElement } from './FridayScratchElement';
import { Pagination } from '../../../components/UI/pagination/Pagination';
import { FridayScratchListModelType } from '../../../models/domain/friday-scratch/FridayScratchTableModel';

export const FridayScratchList = observer(
  (props: {
    model: FridayScratchListModelType;
    noElementsComponent: JSX.Element;
    openScratchDetailsPage: (id: number) => void;
    openModal: () => void;
    currentPage: any;
    activePaginationPage: number;
    lastPaginationPage: number;
  }): JSX.Element => {
    const {
      model,
      noElementsComponent,
      currentPage,
      activePaginationPage,
      lastPaginationPage,
    } = props;

    const showPagination = model.pagination.totalPages > 1;

    const handleOpenModal = (id: number) => {
      currentPage.setSelectedId(id);
      props.openModal();
    };

    return (
      <div className={styles.Container}>
        {/* No elements */}
        <Show condition={!model?.elements?.length}>
          <div className={styles.NoElementsContainer}>
            {noElementsComponent}
          </div>
        </Show>

        {/* List elements */}
        <Show condition={model?.elements?.length}>
          {model.elements.map(
            (elem: FridayScratchListElementModelType, index: number) => {
              return (
                <FridayScratchElement
                  key={elem.id}
                  model={elem}
                  openScratchDetailsPage={props.openScratchDetailsPage}
                  currentPage={currentPage}
                  index={elem.id}
                  activePaginationPage={activePaginationPage}
                  lastPaginationPage={lastPaginationPage}
                  naturalIndex={index}
                  currentPageElementCount={model.elements.length}
                  openModal={() => handleOpenModal(elem.id)}
                />
              );
            }
          )}

          {/* Pagination */}
          {showPagination && (
            <div className={styles.PaginationOuterContainer}>
              <Pagination model={model.pagination} />
            </div>
          )}
        </Show>
      </div>
    );
  }
);
