export const COLUMN_SETTINGS = [
  { id: 'ad_id', label: 'ID oglasa', width: 120 },
  { id: 'ad_status', label: 'Status oglasa', width: 120 },
  {
    id: 'ad_common_code',
    label: 'Kodovi',
    width: 200,
    copy: true,
  },
  { id: 'ad_rejection_reason', label: 'Komentar', width: 80, maxWidth: 250 },
  {
    id: 'partner_name',
    label: 'Naziv partnera',
    width: 270,
  },
  { id: 'partner_legal_name', label: 'Pravni naziv partnera', width: 270 },
  {
    id: 'ad_name',
    label: 'Naslov ponude',
    width: 360,
  },
  { id: 'ad_recommended', label: 'Preporučen oglas', width: 90 },
  { id: 'ad_hidden', label: 'Popust iznenađenja', width: 90 },
  {
    id: 'ad_keywords',
    label: 'Ključne reči',
    width: 340,
  },
  { id: 'ad_date_start', label: 'Početak kampanje', width: 100 },
  {
    id: 'ad_date_end',
    label: 'Kraj kampanje',
    width: 100,
  },
  { id: 'ad_date_range', label: 'Period vremena', width: 200 },
  {
    id: 'ad_date_code_start',
    label: 'Početak kupona',
    width: 100,
  },
  { id: 'ad_date_code_end', label: 'Kraj kupona', width: 100 },
  {
    id: 'category_name',
    label: 'Kategorije proizvoda',
    width: 200,
  },
  { id: 'subcategory_name', label: 'Potkategorije proizvoda', width: 200 },
  {
    id: 'ad_visual_type',
    label: 'Tip oglasa',
    width: 80,
  },
  {
    id: 'ad_store_location_type',
    label: 'Za koje lokacije ponuda važi',
    width: 250,
  },
  {
    id: 'ad_url',
    label: 'Direktan link ka ponudi na Vašem sajtu',
    width: 100,
  },
  { id: 'ad_original_price', label: 'Originalna cena (RSD)', width: 100 },
  {
    id: 'ad_discount_value',
    label: 'Snižena cena (RSD)',
    width: 100,
  },
  { id: 'ad_label_text', label: 'Labela', width: 150 },
  {
    id: 'ad_discount_percentage',
    label: 'Brojna vrednost popusta',
    width: 80,
  },
  { id: 'ad_with_code', label: 'Vrsta koda', width: 100 },
  { id: 'ad_code_type', label: 'Tip koda', width: 235 },
  {
    id: 'ad_type',
    label: 'Tip ponude',
    width: 130,
  },
  { id: 'ad_user_locations', label: 'Lokacija korisnika', width: 160 },
  {
    id: 'ad_gender',
    label: 'Pol',
    width: 80,
  },
  { id: 'ad_age_from', label: 'Godine od', width: 50 },
  { id: 'ad_age_to', label: 'Godine do', width: 50 },
  {
    id: 'ad_territory_type',
    label: 'Urbano ili ruralno područje',
    width: 100,
  },
  { id: 'ad_user_contract_type', label: 'Segmenti korisnika', width: 100 },
  {
    id: 'ad_spender_type',
    label: 'Prema potrošnji u mobilnoj mreži',
    width: 280,
  },
  {
    id: 'ad_code_count',
    label: 'Broj kupona koje želim da podelim',
    width: 100,
  },
  {
    id: 'ad_claimed_codes',
    label: 'Sačuvano kupona',
    width: 100,
  },
  { id: 'ad_views', label: 'Broj pregleda oglasa', width: 100 },
  {
    id: 'creator',
    label: 'Ko je postavio oglas',
    width: 180,
  },
  { id: 'publisher', label: 'Ko je odobrio oglas', width: 100 },
  {
    id: 'partnership_expert',
    label: 'Partnership expert',
    width: 140,
  },
  { id: 'partner_admin_name', label: 'Partner Ime i Prezime', width: 140 },
  {
    id: 'partner_admin_email',
    label: 'Partner mail adresa',
    width: 100,
  },
  { id: 'partner_admin_phone', label: 'Partner broj telefona', width: 130 },
  { id: 'partner_segmentation', label: 'Segmentacija partnera', width: 130 },
  { id: 'ad_published_at', label: 'Datum postavljanja oglasa', width: 130 },
  { id: 'ad_code_spent_at', label: 'Datum potrošenog koda', width: 130 },
  { id: 'ad_expired_at', label: 'Datum isteka oglasa', width: 130 },
  { id: 'partner_segmentation', label: 'Segmentacija partnera', width: 130 },
  { id: 'used_codes_count', label: 'Broj iskorišćenih kupona', width: 130 },
];
