import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ALLOWED_ACTIVE_BANNERS,
  BANNER_PAGE_SIZE,
} from '../../../constants/banners';
import i18n from '../../../i18n';
import {
  BannersListElementModel,
  BannersListElementModelType,
  BannersTableModel,
  ConfirmationModalModel,
  FridayScratchApiType,
  ModalModel,
  PageModel,
  PaginationModel,
  RootType,
  TabModel,
  TabsModel,
} from '../../../internal';
import { FridayScratchTableModel } from '../../../models/domain/friday-scratch/FridayScratchTableModel';

export const BannersPageModel = types
  .compose(
    PageModel,
    types.model({
      selectedId: types.maybeNull(types.number),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedId(id: number) {
        self.selectedId = id;
      },
      resetSelectedId() {
        self.selectedId = null;
      },
      async onPageExit() {
        // prevent caching
        self.clearData();
      },
      async beforePageEnter() {
        await this.getBanners();
        await this.getFridayScratchBanners();
      },

      getMaxActiveBannersCount() {
        const selectedTab = this.getTabs().selectedTab;
        return ALLOWED_ACTIVE_BANNERS[
          selectedTab as keyof typeof ALLOWED_ACTIVE_BANNERS
        ];
      },

      checkIfBannerCanBeActivated(id?: number) {
        const maxActive = this.getMaxActiveBannersCount();
        const banners = this.getBannersList().elements;
        const activeCount = banners.filter(
          (item: BannersListElementModelType) => item.isActive
        ).length;
        const banner = banners.find(
          (banner: BannersListElementModelType) => banner.id === id
        );
        const isActive = banner?.isActive;
        return isActive || activeCount < maxActive;
      },

      async changeOrder(bannerId: string, bannerType: string, change: number) {
        await self.root.api.banners.changeOrder(bannerId, bannerType, change);
        const currentPage = this.getBannersList().pagination.currentPage;
        await this.getBanners(false, currentPage);
      },

      async toggleActive(bannerId: number, isActive: boolean) {
        const canActivate = this.checkIfBannerCanBeActivated(bannerId);
        if (!canActivate && isActive) {
          this.openWarningModal();
          return;
        }
        await self.root.api.banners.toggleActive(bannerId, isActive);
        const currentPage = this.getBannersList().pagination.currentPage;
        await this.getBanners(false, currentPage);
      },

      async getBanners(resetPagination = true, pageNum = 1) {
        if (resetPagination) {
          this.resetPagination();
        }

        const type =
          this.getTabs().selectedTab === 'banners_horizontal'
            ? '0'
            : this.getTabs().selectedTab === 'banners_friday'
            ? '2'
            : '1';
        const response = await self.root.api.banners.getList(pageNum);
        const bannerElements = this.generateBanners(
          response.data.filter(
            (item: BannersListElementModelType) => item.bannerType === type
          )
        ).sort((a, b) => (a.order > b.order ? 1 : -1));
        this.setTotalPagesFromBannerCount(bannerElements.length);
        this.getBannersList().setElements(bannerElements);
      },

      async getFridayScratchBanners(resetPagination = true, pageNum = 1) {
        if (resetPagination) {
          this.resetPagination();
        }
        const response = await self.root.api.fridayScratchBanners.getList(
          pageNum
        );
        const fridayBanners = response.data.map(
          (fridayBanner: FridayScratchApiType) => fridayBanner
        );
        const activeFridayBanners = fridayBanners.filter(
          (fridayBanner: FridayScratchApiType) => fridayBanner.isActive === 1
        );
        this.getFridayScratchList().setElements(activeFridayBanners);
      },

      generateBanners(data: BannersListElementModelType[]) {
        const bannersList: BannersListElementModelType[] = [];
        data.forEach((banner: BannersListElementModelType) => {
          bannersList.push(BannersListElementModel.create(banner));
        });
        return bannersList;
      },

      setTotalPagesFromBannerCount(totalBanners: number) {
        if (!totalBanners) {
          this.getBannersList().pagination.setTotalPages(1);
          return;
        }
        const totalPages = Math.ceil(totalBanners / BANNER_PAGE_SIZE);
        this.getBannersList().pagination.setTotalPages(totalPages);
      },

      getConfirmationModal(id: string) {
        return (
          self.components.get('confirmation_modal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'confirmation_modal',
              labelNo: 'basic:no',
              labelYes: 'basic:yes',
              afterYes: () => this.deleteBanner(id),
              afterNo: () => this.resetSelectedId(),
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      openConfirmationModal(id: string) {
        this.getConfirmationModal(id).open();
      },
      async deleteBanner(id: string) {
        const paramsId = self.root.router.queryParams.id;
        if (self.selectedId) {
          await self.root.api.fridayScratchBanners.delete(self.selectedId);
        } else if (paramsId) {
          await self.root.api.fridayScratchBanners.delete(paramsId);
        }
        await this.getFridayScratchBanners();
        this.resetSelectedId();
      },

      getBannersList() {
        return (
          self.components.get('BannerList') ||
          self.addComponent(
            BannersTableModel.create({
              id: 'BannerList',
              columns: {},
              pagination: PaginationModel.create({
                id: 'BannerListPagination',
                currentPage: 1,
                totalPages: 1,
                pageChangedCallback: (pageNum: number) => {
                  this.getBanners(false, pageNum);
                },
              }),
            })
          )
        );
      },

      getFridayScratchList() {
        return (
          self.components.get('FridayScratchList') ||
          self.addComponent(
            FridayScratchTableModel.create({
              id: 'FridayScratchList',
              columns: {},
              pagination: PaginationModel.create({
                id: 'FridayScratchListPagination',
                currentPage: 1,
                totalPages: 1,
                pageChangedCallback: (pageNum: number) => {
                  this.getFridayScratchBanners(false, pageNum);
                },
              }),
            })
          )
        );
      },

      resetPagination() {
        this?.getBannersList()?.pagination?.setCurrentPage(1);
      },

      getTabs() {
        return (
          self.components.get('Tabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'Tabs',
              selectedTab: self.root.router.params.type
                ? `banners_${self.root.router.params.type}`
                : 'banners_horizontal',
              tabs: {
                horizontal: TabModel.create({
                  id: 'banners_horizontal',
                  name: i18n.t('tabs:banners_horizontal'),
                }),
                popup: TabModel.create({
                  id: 'banners_popup',
                  name: i18n.t('tabs:banners_popup'),
                }),
                yFriday: TabModel.create({
                  id: 'banners_friday',
                  name: i18n.t('tabs:yettel_friday'),
                }),
              },
              onChangeCallback: () => {
                if (this.getTabs().selectedTab === 'banners_friday') {
                  this.getFridayScratchBanners();
                } else {
                  this.getBanners();
                }
              },
            })
          )
        );
      },

      getWarningModal() {
        return (
          self.components.get('warning_modal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'warning_modal',
              labelYes: 'basic:ok',
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      openWarningModal() {
        this.getWarningModal().open();
      },
    };
  })
  .named('BannersPageModel');

export type BannersPageType = Instance<typeof BannersPageModel>;
