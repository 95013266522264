import { ReactElement } from 'react';
import { OfferDetailsPreview } from '../../../../../components/domain/offer-details-preview/OfferDetailsPreview';
import { Rejection, useMst } from '../../../../../internal';
import styles from '../AdDetails.module.scss';
import * as React from 'react';
import { Show } from '../../../../../components/UI/show/Show';
import { StandardAd } from '../../../../../components/UI/adtype/standardAd/StandardAd';
import { FeaturedAd } from '../../../../../components/UI/adtype/featuredAd/FeaturedAd';
import { TopAd } from '../../../../../components/UI/adtype/topAd/TopAd';
import { observer } from 'mobx-react';
import { TOP, STANDARD, FEATURED } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import thumbsUp from '../../../../../assets/icons/thumbs-up.png';
import {
  EXPIRED,
  PUBLISHED,
  REJECTED,
  STOPPED,
  SUBMITTED,
  CODE_SPENT,
  PUBLISHED_SOON,
} from '../../../../../constants/ad';
import DOMPurify from 'dompurify';
import { dateSRLocale, parseDate } from '../../../../../utils/date';
import { AdAnalyticsView } from '../ad-analytics-view/AdAnalyticsView';
import DOWNLOAD from '../../../../../assets/icons/icon-download.png';
import { Dropdown } from '../../../../../components/UI/dropdown/Dropdown';
import { InputField } from '../../../../../components/UI/input/InputField';
import { LightButton } from '../../../../../components/UI/buttons/light-button/LightButton';
import { TextAreaInputField } from '../../../../../components/UI/textarea/TextAreaInputField';

const AdditionalData = observer(() => {
  const {
    header: { isAdministrator, isModerator },
    currentPage,
  } = useMst();
  const { t } = useTranslation();
  const isSubmitted = () => currentPage.adDetails.status === SUBMITTED;
  const isRejected = () => currentPage.adDetails.status === REJECTED;
  const isStopped = () => currentPage.adDetails.status === STOPPED;
  const isCodeSpent = () => currentPage.adDetails.status === CODE_SPENT;

  const isPublished = () => currentPage.adDetails.status === PUBLISHED;
  const isPublishedSoon = () => currentPage.adDetails.status === PUBLISHED_SOON;
  const isExpired = () => currentPage.adDetails.status === EXPIRED;
  const shouldShowApprovedBy = () =>
    isPublished() || isExpired() || isStopped() || isCodeSpent();
  const shouldShowAdAttributes = () =>
    (isAdministrator || isModerator) &&
    (isSubmitted() || isPublished() || isPublishedSoon());
  return (
    <div className={styles.AdditionalDetails}>
      <div className={`${styles.Element}`}>
        <div className={styles.ElementTitle}>{t('basic:ad_submitted_by')}</div>
        <div className={styles.ElementText}>{currentPage.adSubmitter}</div>
      </div>
      {shouldShowApprovedBy() && (
        <div className={`${styles.Element}`}>
          <div className={styles.ElementTitle}>{t('basic:ad_approved_by')}</div>
          <div className={styles.ElementText}>{currentPage.adApprovedBy}</div>
        </div>
      )}
      {shouldShowAdAttributes() && (
        <>
          <div className={`${styles.ElementCenterText}`}>
            <InputField model={currentPage.getLabelText()} />
            <Dropdown model={currentPage.getLabelColorDropdown()} />
            <span
              className={styles.Link}
              onClick={() => {
                currentPage.setLabelColor(
                  currentPage.getLabelColorDropdown().value
                );
                currentPage.setLabelText(currentPage.getLabelText().value);
                currentPage.getLabelColor();
              }}
            >
              {t('basic:add_label')}
            </span>
          </div>
          <div className={`${styles.Element}`}>
            <div className={styles.Label}>
              <span>{t('basic:recommended_ads')}</span>
              <img
                src={thumbsUp}
                alt="thumbs-up"
                className={styles.GoodQualityIcon}
              />
            </div>
            <div className={styles.DropdownContainer}>
              <Dropdown model={currentPage.getRecommendedDropdown()} />
            </div>
          </div>
          <div className={`${styles.Element}`}>
            <div className={styles.Label}>
              <span>{t('basic:special_ads')}</span>
            </div>
            <div className={styles.DropdownContainer}>
              <Dropdown model={currentPage.getHiddenDropdown()} />
            </div>
          </div>
        </>
      )}
      <div className={`${styles.Element}`}>
        <div
          onClick={() => {
            currentPage.downloadPictures();
          }}
          className={styles.DownloadPictures}
        >
          <img
            alt={'download'}
            className={styles.DownloadImage}
            src={DOWNLOAD}
          />{' '}
          <div className={styles.Link}>{t('basic:download_pictures')}</div>
        </div>
      </div>
      {shouldShowAdAttributes() && (
        <LightButton
          onClick={() => {
            currentPage.saveAttributes();
          }}
        >
          {t('basic:save_change')}
        </LightButton>
      )}
      {isSubmitted() && currentPage.isPartnerView && (
        <div
          className={`${styles.Element} ${styles.TopBorder} ${styles.TopPadding}`}
        >
          <div className={styles.ElementTitle}>
            {t('basic:sent_for_approval')}:
          </div>
          <div className={styles.ElementText}>
            {dateSRLocale(
              parseDate(currentPage?.adDetails?.created_at?.split?.(' ')?.[0])
            )}
          </div>
        </div>
      )}
      {isRejected() && currentPage.isPartnerView && (
        <>
          <div
            className={`${styles.Element} ${styles.TopBorder}  ${styles.TopPadding}`}
          >
            <div className={styles.ElementTitle}>{t('basic:ad_rejected')}</div>
            <div className={styles.ElementText}>
              {dateSRLocale(
                parseDate(
                  (
                    currentPage?.adDetails?.rejections?.[0] as Rejection
                  )?.created_at.split(' ')[0]
                )
              )}
            </div>
          </div>
          <div
            className={`${styles.Element}  ${styles.TopBorder}  ${styles.TopPadding}`}
          >
            <div className={styles.ElementTitle}>
              {t('basic:ad_rejection_reason')}
            </div>
            <div
              className={styles.ElementText}
              dangerouslySetInnerHTML={{
                __html: (currentPage?.adDetails?.rejections[0] as Rejection)
                  .reason,
              }}
            ></div>
          </div>
          <div className={`${styles.Element}`}>
            <div className={styles.ElementTitle}>{t('basic:moderator')}</div>
            <div className={styles.ElementText}>
              {currentPage?.rejectedByDetails?.email}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export const AdOverview = observer((): ReactElement => {
  const {
    header: { isAdministrator, isModerator },
    currentPage,
  } = useMst();
  const { t } = useTranslation();
  const isPublished = () => currentPage.adDetails.status === PUBLISHED;
  const isStopped = () => currentPage.adDetails.status === STOPPED;
  const isCodeSpent = () => currentPage.adDetails.status === CODE_SPENT;
  const isExpired = () => currentPage.adDetails.status === EXPIRED;
  const isSubmitted = () => currentPage?.adDetails?.status === SUBMITTED;
  const shouldShowAdAnalytics = () =>
    currentPage.isPartnerView &&
    (isPublished() || isExpired() || isStopped() || isCodeSpent());
  const shouldShowForAdministratorOrModerator = () =>
    !currentPage.isPartnerView && (isAdministrator || isModerator);
  const shouldShowComments = isSubmitted() && (isAdministrator || isModerator);
  return (
    <div className={styles.AdDetailTabContainer}>
      <div className={styles.AdOverviewContainer}>
        <div
          className={`${styles.AdTypePreviewContainer} ${styles.AdPreviewPart}`}
        >
          <div className={styles.PEventsNone}>
            <Show condition={currentPage.getVisualType() === STANDARD}>
              <StandardAd
                uiOptions={{ hideDistanceIcon: true }}
                model={currentPage.getAdTypeModel()}
              />
            </Show>
            <Show condition={currentPage.getVisualType() === FEATURED}>
              <FeaturedAd
                uiOptions={{ hideDistanceIcon: true }}
                model={currentPage.getAdTypeModel()}
              />
            </Show>
            <Show condition={currentPage.getVisualType() === TOP}>
              <TopAd
                uiOptions={{ hideDistanceIcon: true }}
                model={currentPage.offerPreview}
              />
            </Show>
          </div>
          <AdditionalData />
        </div>
        <div className={`${styles.AdPreviewContainer} ${styles.AdPreviewPart}`}>
          <div className={styles.InnerContainer}>
            <OfferDetailsPreview preview={currentPage.offerPreview} />
          </div>
        </div>
        <div
          className={`${styles.AdStatisticsContainer} ${styles.AdPreviewPart}`}
          style={{ display: 'block' }}
        >
          {shouldShowForAdministratorOrModerator() ? (
            <div className={`${styles.Element}`}>
              {currentPage.adDetails.keywords ? (
                <>
                  <div className={styles.ElementTitle}>
                    {t('basic:keywords')}
                  </div>
                  <div className={styles.ElementText}>
                    {currentPage.adDetails.keywords}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {shouldShowComments &&
            (!currentPage.isPartnerView ? (
              <div className={styles.TextAreaInputField}>
                <TextAreaInputField model={currentPage.getCommentTextArea()} />
              </div>
            ) : (
              <div className={`${styles.Element}`} style={{ display: 'block' }}>
                <>
                  <div className={styles.ElementTitle}>Komentar:</div>
                  <div
                    className={styles.ElementText}
                    dangerouslySetInnerHTML={{
                      __html: currentPage.adDetails.comment,
                    }}
                  />
                </>
              </div>
            ))}
          {shouldShowComments && !currentPage.isPartnerView && (
            <LightButton onClick={currentPage.saveComment}>
              Sačuvaj komentar
            </LightButton>
          )}
          {shouldShowAdAnalytics() && (
            <AdAnalyticsView model={currentPage.adAnalytics} />
          )}
        </div>
      </div>
    </div>
  );
});
