import { getRoot, Instance, types } from 'mobx-state-tree';
import { ComponentModel } from '../../ui/ComponentModel';
import { PaginationModel } from '../../ui/pagination/PaginationModel';
import { RootType } from '../../../rootInstance';
import {
  FridayScratchListElementModel,
  FridayScratchListElementModelType,
} from './FridayScratchListElementModel';

export const FridayScratchTableModel = types
  .compose(
    ComponentModel,
    types.model({
      pagination: types.maybe(types.late(() => PaginationModel)),
      rows: types.array(FridayScratchListElementModel), // Referencing element model
    })
  )
  .views((self) => ({
    get root(): RootType {
      return getRoot(self);
    },
    get elements() {
      return Array.from(self.rows.values());
    },
  }))
  .actions((self) => ({
    clearElements() {
      self.rows = [];
    },
    setElements(fridayScratchArray: FridayScratchListElementModelType[]) {
      self.clearElements();
      fridayScratchArray.forEach((scratchElement) => {
        self.rows.push(scratchElement);
      });
    },
  }))
  .named('FridayScratchTableModel');

export type FridayScratchListModelType = Instance<
  typeof FridayScratchTableModel
>;
