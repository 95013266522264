import { Instance, types } from 'mobx-state-tree';
import { DropdownModel } from '../../../internal';

export const FridayScratchListElementModel = types
  .model({
    id: types.number,
    segmentation: types.string,
    scratchBannerType: types.string,
    image: types.maybeNull(types.string),
    isActive: types.optional(types.number, 1),
    createdAt: types.optional(types.string, ''),
    updatedAt: types.optional(types.string, ''),
  })
  .views((_) => {
    return {};
  })
  .actions((_) => {
    return {};
  })
  .named('FridayScratchModel');

export type FridayScratchListElementModelType = Instance<
  typeof FridayScratchListElementModel
>;
