import React from 'react';

import {
  ViewType,
  CreateOfferPageModel,
  RootType,
  CreateOfferSuccessPageModel,
  LoginModel,
  AllAdsModel,
  PartnerProfilesModel,
  AdDetailsModel,
  CompanyProfileModel,
  AccountsModel,
  ConfirmAdminModel,
  UpdatePartnerAdminEmailModel,
  PartnerOffersPageModel,
  BannersPageModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  AdministrationPageModel,
  PaymentsAndDocumentsModel,
  CreateBannerPageModel,
  CreateFridayScratchModel,
} from '../internal';
import { NotFound } from '../pages/NotFound';
import { CreateOffer } from '../pages/offers/create-offer/CreateOffer';
import { PartnerProfiles } from '../pages/moderator/partner-profiles/PartnerProfiles';
import { PaymentsAndDocuments } from '../pages/payments-and-documents/PaymentsAndDocuments';
import { Support } from '../pages/support/Support';
import { getRoot } from 'mobx-state-tree';
import { CreateOfferSuccess } from '../pages/offers/create-offer/success/CreateOfferSuccess';
import Login from '../pages/login/Login';
import { AllAds } from '../pages/moderator/all-ads/AllAds';
import { Packages } from '../pages/moderator/packages/Packages';
import { Accounts } from '../pages/moderator/accounts/Accounts';
import { AdministrationPage } from '../pages/moderator/administration/AdministrationPage';
import { CompanyProfile } from '../pages/company-profile/CompanyProfile';
import { AdDetails } from '../pages/moderator/all-ads/ad-details/AdDetails';
import { MODERATOR_PROFILE, PARTNER_PROFILES } from '../constants/header';
import ConfirmAdmin from '../pages/confirm-admin/ConfirmAdmin';
import UpdatePartnerAdminEmail from '../pages/update-partner-admin-email/UpdatePartnerAdminEmail';
import { PartnerOffers } from '../pages/offers/all-offers/PartnerOffers';
import ForgotPassword from '../pages/forgot-password/ForgotPassword';
import ResetPassword from '../pages/reset-password/ResetPassword';
import { registerAntiRefresh, unregisterAntiRefresh } from './methods';
import Maintenance from '../pages/maintenance/Maintenance';
import { Banners } from '../pages/moderator/banners/Banners';
import { CreateBanner } from '../pages/moderator/banners/CreateBanner';
import { CreateFridayScratchPage } from '../pages/moderator/friday-scratch/CreateFridayScratch';

type HookType = (
  view: ViewType,
  params: any,
  queryParams: Queries
) => Promise<boolean | void>;

interface IHooksInterface {
  beforeEnter?: HookType;
  onEnter?: HookType;
  beforeExit?: HookType;
  onExit?: HookType;
  queryParams?: Queries;
}

export type Query = {
  name: string;
  required: boolean;
};

export type Queries = {
  [key: string]: Query | boolean | string;
};

interface IPageRoute {
  component: React.ComponentElement<any, any>;
  name: string;
  path: string;
  isAuthenticationRequired: boolean;
  hooks?: IHooksInterface;
  id: string;
  queryParams?: Queries;
  functionality?: string;
  extension?: any;
  alwaysAccessible?: boolean;
  canAccess?: (self: any) => boolean;
}

interface IPageRoutes {
  [key: string]: IPageRoute;
}

export const PageRoutes: IPageRoutes = {
  Maintenance: {
    component: <Maintenance />,
    name: 'Maintenance',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/maintenance`,
    id: 'Maintenance',
    isAuthenticationRequired: false,
  },
  NotFound: {
    component: <NotFound />,
    name: 'Not Found',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/404`,
    id: 'NotFound',
    isAuthenticationRequired: false,
  },
  CreateOffer: {
    component: <CreateOffer />,
    name: 'Kreiraj oglas',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/create-offer`,
    id: 'CreateOffer',
    isAuthenticationRequired: true,
    extension: CreateOfferPageModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isPartner ||
        root.user.isModerator ||
        root.user.isAdministrator ||
        root.user.isSalesman
      );
    },
    hooks: {
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        if (root?.router?.queryParams?.updateAd && !root.data.adDetails) {
          return root.router.navigate({
            newView: PageRoutes.AdDetailsPartner.id,
            shouldReplace: true,
            queryParams: {
              id: root?.router?.queryParams?.updateAd,
            },
          });
        }
        // we know that he is going to create new offer because there is no query parameters -> reset previous ad details
        if (
          !root?.router?.queryParams.hasOwnProperty('updateAd') &&
          root.data.adDetails
        ) {
          root.data.resetAdDetails();
          const adDetailsPage = root.pages.get(PageRoutes.AdDetailsPartner.id);
          adDetailsPage.resetCreateAdPage(root.pages.get(self.id));
        }
      },
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        root.header?.setActiveTab(PARTNER_PROFILES);
        registerAntiRefresh();
        return page.beforePageEnter();
      },
      beforeExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageExit();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        unregisterAntiRefresh();
        return page.onPageExit();
      },
    },
  },
  Login: {
    component: <Login />,
    name: 'Yettel Shopping Admin Panel',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/login`,
    id: 'Login',
    isAuthenticationRequired: false,
    extension: LoginModel,
  },
  ForgotPassword: {
    component: <ForgotPassword />,
    name: 'Zaboravljena lozinka',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/forgot-password`,
    id: 'ForgotPassword',
    isAuthenticationRequired: false,
    extension: ForgotPasswordModel,
    hooks: {
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  ConfirmAdmin: {
    component: <ConfirmAdmin />,
    name: 'ConfirmAdmin',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/confirm-admin`,
    id: 'ConfirmAdmin',
    isAuthenticationRequired: false,
    extension: ConfirmAdminModel,
    alwaysAccessible: true,
    hooks: {
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  ResetPassword: {
    component: <ResetPassword />,
    name: 'ResetPassword',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/reset-password`,
    id: 'ResetPassword',
    isAuthenticationRequired: false,
    extension: ResetPasswordModel,
    alwaysAccessible: true,
    hooks: {
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  UpdatePartnerAdmin: {
    component: <UpdatePartnerAdminEmail />,
    name: 'UpdatePartnerAdmin',
    path: `${
      process.env.REACT_APP_PATH_SUFFIX || ''
    }/partner/update-partner-admin-email`,
    id: 'UpdatePartnerAdmin',
    isAuthenticationRequired: false,
    extension: UpdatePartnerAdminEmailModel,
    alwaysAccessible: true,
    hooks: {
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  AllAds: {
    component: <AllAds />,
    name: 'Yettel Shopping Partner',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/`,
    id: 'AllAds',
    extension: AllAdsModel,
    isAuthenticationRequired: true,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isModerator ||
        root.user.isAdministrator ||
        root.user.isSalesman
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        // can access doesn't do the job fully :/
        if (root.user.isPartner) {
          return false;
        }
        const page = root.pages.get(self.id);
        root.header?.setActiveTab(MODERATOR_PROFILE);
        return page.beforePageEnter();
      },
    },
  },
  HomeRedirect: {
    component: <></>,
    name: 'HomeRedirect',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/`,
    id: 'HomeRedirect',
    isAuthenticationRequired: true,
    hooks: {
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        if (!root.user.isPartner) {
          return root.router.navigate({
            newView: PageRoutes.AllAds.id,
          });
        }
        return root.router.navigate({
          newView: PageRoutes.PartnerOffers.id,
        });
      },
    },
  },
  HomeRedirect2: {
    component: <></>,
    name: 'HomeRedirect2',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}`,
    id: 'HomeRedirect2',
    isAuthenticationRequired: true,
    hooks: {
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        if (!root.user.isPartner) {
          return root.router.navigate({
            newView: PageRoutes.AllAds.id,
          });
        }
        return root.router.navigate({
          newView: PageRoutes.PartnerOffers.id,
        });
      },
    },
  },
  SSORedirect: {
    component: <></>,
    name: 'SSORedirect',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/sso/login`,
    id: 'SSORedirect',
    isAuthenticationRequired: false,
    hooks: {
      beforeEnter: async (self) => {
        const root: RootType = getRoot(self);
        const token = root?.router?.queryParams?.token;
        try {
          const response = await root.api.admin.ssoLogin({ token });
          await root.user.setLoginData(response.data);
          return true;
        } catch (err) {
          root.reset();
          return true;
        }
      },
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        return root.router.navigate({
          newView: PageRoutes.HomeRedirect.id,
        });
      },
    },
  },
  Packages: {
    component: <Packages />,
    name: 'Packages',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/packages/`,
    id: 'Packages',
    isAuthenticationRequired: true,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isAdministrator;
    },
  },
  Accounts: {
    component: <Accounts />,
    name: 'Accounts',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/accounts/`,
    id: 'Accounts',
    isAuthenticationRequired: true,
    extension: AccountsModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isModerator ||
        root.user.isAdministrator ||
        root.user.isSalesman
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        root.header?.setActiveTab(MODERATOR_PROFILE);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
    },
  },
  Administration: {
    component: <AdministrationPage />,
    name: 'Administration',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/administration/`,
    id: 'Administration',
    isAuthenticationRequired: true,
    extension: AdministrationPageModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isModerator ||
        root.user.isAdministrator ||
        root.user.isSalesman
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
    },
  },
  CreateOfferSuccess: {
    component: <CreateOfferSuccess />,
    name: 'CreateOfferSuccess',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/success/`,
    id: 'CreateOfferSuccess',
    extension: CreateOfferSuccessPageModel,
    isAuthenticationRequired: true,
  },
  PartnerOffers: {
    component: <PartnerOffers />,
    name: 'PartnerOffers',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/offers/`,
    id: 'PartnerOffers',
    extension: PartnerOffersPageModel,
    isAuthenticationRequired: true,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isPartner ||
        root.user.isSalesman ||
        root.user.isModerator ||
        root.user.isAdministrator
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        root.header?.setActiveTab(PARTNER_PROFILES);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  CompanyProfile: {
    component: <CompanyProfile />,
    name: 'CompanyProfile',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/company-profile/`,
    id: 'CompanyProfile',
    extension: CompanyProfileModel,
    isAuthenticationRequired: true,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isPartner ||
        root.user.isSalesman ||
        root.user.isModerator ||
        root.user.isAdministrator
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        root.header?.setActiveTab(PARTNER_PROFILES);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.onPageExit();
      },
    },
  },
  PartnerProfiles: {
    component: <PartnerProfiles />,
    name: 'PartnerProfiles',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/partner-profile/`,
    id: 'PartnerProfiles',
    extension: PartnerProfilesModel,
    isAuthenticationRequired: true,
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        root.header?.setActiveTab(PARTNER_PROFILES);
        return Promise.resolve(true);
      },
    },
  },
  PaymentsAndDocuments: {
    component: <PaymentsAndDocuments />,
    name: 'PaymentsAndDocuments',
    path: `${
      process.env.REACT_APP_PATH_SUFFIX || ''
    }/partner/payments-and-documents/`,
    id: 'PaymentsAndDocuments',
    isAuthenticationRequired: true,
    extension: PaymentsAndDocumentsModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isPartner ||
        root.user.isSalesman ||
        root.user.isModerator ||
        root.user.isAdministrator
      );
    },
    hooks: {
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        root.header?.setActiveTab(PARTNER_PROFILES);
        return Promise.resolve(true);
      },
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
    },
  },
  Support: {
    component: <Support />,
    name: 'Support',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/support/`,
    id: 'Support',
    isAuthenticationRequired: true,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isPartner ||
        root.user.isSalesman ||
        root.user.isModerator ||
        root.user.isAdministrator
      );
    },
    hooks: {
      onEnter: (self) => {
        const root: RootType = getRoot(self);
        root.header?.setActiveTab(PARTNER_PROFILES);
        return Promise.resolve(true);
      },
    },
  },
  AdDetails: {
    component: <AdDetails />,
    name: 'AdDetails',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/ad-details`,
    id: 'AdDetails',
    isAuthenticationRequired: true,
    extension: AdDetailsModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return (
        root.user.isModerator ||
        root.user.isAdministrator ||
        root.user.isSalesman
      );
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },
  AdDetailsPartner: {
    component: <AdDetails />,
    name: 'AdDetailsPartner',
    path: `${
      process.env.REACT_APP_PATH_SUFFIX || ''
    }/partner/ad-details-partner`,
    id: 'AdDetailsPartner',
    isAuthenticationRequired: true,
    extension: AdDetailsModel,
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },
  Banners: {
    component: <Banners />,
    name: 'Banners',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/banners`,
    id: 'Banners',
    isAuthenticationRequired: true,
    extension: BannersPageModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isModerator || root.user.isAdministrator;
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },
  BannerDetails: {
    component: <CreateBanner />,
    name: 'BannerDetails',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/banner`,
    id: 'BannerDetails',
    isAuthenticationRequired: true,
    extension: CreateBannerPageModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isModerator || root.user.isAdministrator;
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },
  CreateBanner: {
    component: <CreateBanner />,
    name: 'CreateBanner',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/banners/create`,
    id: 'CreateBanner',
    isAuthenticationRequired: true,
    extension: CreateBannerPageModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isModerator || root.user.isAdministrator;
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },

  FridayScratchDetails: {
    component: <CreateFridayScratchPage />,
    name: 'FridayScratchDetails',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/banner`,
    id: 'FridayScratchDetails',
    isAuthenticationRequired: true,
    extension: CreateFridayScratchModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isModerator || root.user.isAdministrator;
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },

  CreateFridayScratch: {
    component: <CreateFridayScratchPage />,
    name: 'CreateFridayScratch',
    path: `${process.env.REACT_APP_PATH_SUFFIX || ''}/partner/banners/create`,
    id: 'CreateFridayScratch',
    isAuthenticationRequired: true,
    extension: CreateFridayScratchModel,
    canAccess: (self): boolean => {
      const root: RootType = getRoot(self);
      return root.user.isModerator || root.user.isAdministrator;
    },
    hooks: {
      beforeEnter: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        return page.beforePageEnter();
      },
      onExit: (self) => {
        const root: RootType = getRoot(self);
        const page = root.pages.get(self.id);
        page.clearData();
        return Promise.resolve(true);
      },
    },
  },
};
