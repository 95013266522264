import { ApiListResponse, ApiObjectResponse, RootType } from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';

const PAGE_SIZE = 20;

export type ScratchBannerSegmentationType = {
  POSTPAID: '0';
  BUSINESS: '1';
};

export type ScratchBannerType = {
  SCRATCH_OVERLAY: '0';
  COUNTDOWN_OVERLAY: '1';
};

export interface FridayScratchApiType {
  id?: string;
  image: string | null;
  segmentation: ScratchBannerSegmentationType;
  scratchBannerType: ScratchBannerType;
  isActive?: number;
  created_at?: string;
  updated_at?: string;
}

export const FridayScratchApi = types
  .model('FridayScratchApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async create(data: FridayScratchApiType) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.POST,
            path: '/scratch-banners',
            data,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async update(id: string, data: FridayScratchApiType) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.PATCH,
            path: `/scratch-banners/${id}`,
            data,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async get(id: string): Promise<FridayScratchApiType | undefined | null> {
        const response: ApiObjectResponse<FridayScratchApiType> =
          await self.root.axios.requestData({
            type: HttpVerbs.GET,
            path: `/scratch-banners/${id}`,
          });
        return response?.data;
      },
      async getList(
        page: number
      ): Promise<ApiListResponse<FridayScratchApiType>> {
        return await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/scratch-banners?page=${page}&limit=${PAGE_SIZE}`,
        });
      },
      async delete(id: string) {
        try {
          return await self.root.axios.requestData({
            type: HttpVerbs.DELETE,
            path: `/scratch-banners/${id}`,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async uploadImage(file: File, fileKey: string) {
        try {
          return await self.root.axios.uploadFile({
            path: '/scratch-banners/upload',
            file,
            fileKey,
          });
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
    };
  });
