import { RootModel, PageRoutes, startRouter } from './internal';
import { createContext, useContext } from 'react';
import { Instance } from 'mobx-state-tree';

if (location.origin === 'https://svakidan.yettel.rs') {
  location.assign('https://shoppingadmin.yettel.rs/partner/');
}
export const rootInstance = RootModel.create({
  api: {
    offers: {},
    banners: {},
    fridayScratchBanners: {},
    adUpdate: {},
    admin: {},
    category: {},
    contract: {},
    contractRequest: {},
    partner: {},
    partnerLocation: {},
    purchasedExtension: {},
    purchases: {},
    subcategory: {},
    user: {},
    geolocation: {},
    usedCodes: {},
    invoices: {},
    partnerDocument: {},
  },
  axios: {},
  data: {},
  geolocation: {},
  header: {},
  router: {
    views: PageRoutes,
  },
  snackbars: {},
  scrollSubscribers: {},
  status: {},
  toasts: {},
  user: {},
});
startRouter(rootInstance.router);

type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(rootInstance);

export const Provider = RootStoreContext.Provider;

export function useMst(): RootInstance {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as RootInstance;
}

export const ProviderWrapper = ({ children }: { children: any }): any => {
  return <Provider value={rootInstance}>{children}</Provider>;
};

export type RootType = Instance<typeof RootModel>;
